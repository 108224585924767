import React, { useState, useRef, useEffect } from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';
import geoJSON from '../../../assets/json/worldGeoJSON.json';
import ChartLegend from '../ChartLegend';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { minusSignSVG, plusSignSVG } from '../../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';

type Props = {
  data: any[];
};

const worldCountries = geoJSON as any;
//
const WorldMapChart = ({ data }: Props) => {
  const { t } = useTranslation();
  const [projectionScale, setProjectionScale] = useState(120);
  const [projectionTranslation, setProjectionTranslation] = useState<
    [number, number]
  >([0.5, 0.5]);
  const mapRef = useRef<HTMLDivElement>(null);

  const transformedData = data.map((item) => ({
    ...item,
    value: item.value || item.count || 0,
  }));

  const domainMax = Math.max(...transformedData.map((item) => item.value));

  const highlightColor = '#82ca9d';

  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState<[number, number]>([0, 0]);

  const [touchStart, setTouchStart] = useState<[number, number] | null>(null);
  const [initialScale, setInitialScale] = useState(projectionScale);

  const MIN_TRANSLATION_X = 0.1;
  const MAX_TRANSLATION_X = 0.9;
  const MIN_TRANSLATION_Y = 0.1;
  const MAX_TRANSLATION_Y = 0.9;

  // Handle zooming (mouse scroll) while preventing page scroll
  const handleWheel = (event: WheelEvent) => {
    event.preventDefault(); // Prevent page scroll

    const zoomFactor = event.deltaY < 0 ? 1.1 : 0.9; // Zoom in or out based on scroll direction
    const newScale = projectionScale * zoomFactor;

    // Limit the zoom scale to a defined range
    if (newScale >= 80 && newScale <= 300) {
      setProjectionScale(newScale);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setDragging(true);
    setStartPos([event.clientX, event.clientY]);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (dragging) {
      const dx = (event.clientX - startPos[0]) / window.innerWidth;
      const dy = (event.clientY - startPos[1]) / window.innerHeight;

      const newTranslationX = projectionTranslation[0] + dx;
      const newTranslationY = projectionTranslation[1] + dy;

      setProjectionTranslation([
        Math.min(
          Math.max(newTranslationX, MIN_TRANSLATION_X),
          MAX_TRANSLATION_X
        ),
        Math.min(
          Math.max(newTranslationY, MIN_TRANSLATION_Y),
          MAX_TRANSLATION_Y
        ),
      ]);

      setStartPos([event.clientX, event.clientY]);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleZoomIn = () => {
    setProjectionScale((prevScale) => Math.min(prevScale * 1.2, 300));
  };

  const handleZoomOut = () => {
    setProjectionScale((prevScale) => Math.max(prevScale * 0.8, 80));
  };

  // Touch events for pinch zoom and drag
  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length === 1) {
      // Single touch drag start
      setDragging(true);
      const touch = event.touches[0];
      setStartPos([touch.clientX, touch.clientY]);
    } else if (event.touches.length === 2) {
      // Start of pinch zoom
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const initialDistance = Math.sqrt(
        (touch1.clientX - touch2.clientX) ** 2 +
          (touch1.clientY - touch2.clientY) ** 2
      );
      setTouchStart([initialDistance, projectionScale]);
    }
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    event.preventDefault(); // Prevent page scroll during touchmove

    if (dragging && event.touches.length === 1) {
      const touch = event.touches[0];
      const dx = (touch.clientX - startPos[0]) / window.innerWidth;
      const dy = (touch.clientY - startPos[1]) / window.innerHeight;

      const newTranslationX = projectionTranslation[0] + dx;
      const newTranslationY = projectionTranslation[1] + dy;

      setProjectionTranslation([
        Math.min(
          Math.max(newTranslationX, MIN_TRANSLATION_X),
          MAX_TRANSLATION_X
        ),
        Math.min(
          Math.max(newTranslationY, MIN_TRANSLATION_Y),
          MAX_TRANSLATION_Y
        ),
      ]);

      setStartPos([touch.clientX, touch.clientY]);
    } else if (event.touches.length === 2 && touchStart) {
      // Pinch zoom handling
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];

      const currentDistance = Math.sqrt(
        (touch1.clientX - touch2.clientX) ** 2 +
          (touch1.clientY - touch2.clientY) ** 2
      );
      const scaleFactor = currentDistance / touchStart[0];
      const newScale = touchStart[1] * scaleFactor;

      // Limit the zoom scale
      if (newScale >= 80 && newScale <= 300) {
        setProjectionScale(newScale);
      }
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
    setTouchStart(null); // Reset pinch zoom tracking
  };

  useEffect(() => {
    const mapElement = mapRef.current;
    if (mapElement) {
      // Add the wheel event listener to the mapRef element with passive: false
      mapElement.addEventListener('wheel', handleWheel as EventListener, {
        passive: false,
      });

      // Clean up the event listener on component unmount
      return () => {
        mapElement.removeEventListener('wheel', handleWheel as EventListener);
      };
    }
  }, [projectionScale, projectionTranslation]);

  // Prevent scrolling when touch events are being handled
  useEffect(() => {
    const mapElement = mapRef.current;
    if (mapElement) {
      // Make sure touch events prevent default scrolling
      mapElement.addEventListener(
        'touchmove',
        handleTouchMove as unknown as EventListener, // Cast to unknown and then to EventListener
        { passive: false }
      );

      return () => {
        mapElement.removeEventListener(
          'touchmove',
          handleTouchMove as unknown as EventListener // Cast here as well
        );
      };
    }
  }, [dragging, touchStart]);

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%', height: '500px' }}
          className='chart__bottom--content-chart-pie'
        >
          <div
            className='worldMapChart'
            ref={mapRef}
            style={{
              width: '100%',
              height: '500px',
              cursor: dragging ? 'grabbing' : 'pointer',
            }}
            onMouseDown={handleMouseDown} // Handle mouse drag start
            onMouseMove={handleMouseMove} // Handle mouse drag move
            onMouseUp={handleMouseUp} // Handle mouse drag end
            onMouseLeave={handleMouseUp} // Stop dragging if mouse leaves the area
            onTouchStart={handleTouchStart} // Handle touch start (single touch drag or pinch zoom)
            onTouchMove={handleTouchMove} // Handle touch move (drag or pinch zoom)
            onTouchEnd={handleTouchEnd} // Handle touch end
          >
            <ResponsiveChoropleth
              data={transformedData}
              features={worldCountries.features}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              colors={[highlightColor]}
              domain={[0, domainMax]}
              unknownColor='#ccc'
              label='properties.name'
              valueFormat='.2s'
              projectionScale={projectionScale}
              projectionTranslation={projectionTranslation}
              borderWidth={0.5}
              borderColor='#152538'
              enableGraticule={false}
              graticuleLineColor='#dddddd'
              isInteractive={true}
              tooltip={({ feature }: { feature: any }) =>
                feature.properties?.name &&
                data.some((d) => d.id === feature.id) ? (
                  <div className='worldMapChart__tooltip'>
                    <div className='worldMapChart__tooltip--name'>
                      {data?.find((d) => d?.id === feature?.id)?.name}
                    </div>
                    <div className='worldMapChart__tooltip--count'>
                      {t('Users')}:{' '}
                      {data?.find((d) => d?.id === feature?.id)?.count}
                    </div>
                  </div>
                ) : null
              }
            />
            <div className='worldMapChart__zoom'>
              <div
                title={t('ZoomIn')}
                onClick={handleZoomIn}
                className='worldMapChart__zoom-in'
              >
                <CustomSVGs svg={plusSignSVG} />
              </div>
              <div
                title={t('ZoomOut')}
                onClick={handleZoomOut}
                className='worldMapChart__zoom-out'
              >
                <CustomSVGs svg={minusSignSVG} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-is-chart-legend-row='true'
        className='chart__bottom--content-chart-legend'
      >
        <div className='chart__bottom--content-chart-legend-content'>
          {transformedData.map((item, index) => (
            <ChartLegend
              key={index}
              color={highlightColor}
              label={item.name}
              value={item.count}
              symbol=''
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default WorldMapChart;
