import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import '../../styles/components/subPageContentHolder/dashboardFilter.scss';

interface Props {
  dashboardPeriodFilterHandler: (value: string) => void;
  timeFilter: string;
}

const DashboardFilterTime = ({
  dashboardPeriodFilterHandler,
  timeFilter,
}: Props) => {
  const { t } = useTranslation();
  const [isPeriodFilterOpen, setIsPeriodFilterOpen] = useState<boolean>(false);

  // Reference to the dashboardFilter__time container
  const filterRef = useRef<HTMLDivElement | null>(null);

  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  // Effect to handle click outside and close the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsPeriodFilterOpen(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={filterRef}
      onClick={() => setIsPeriodFilterOpen(!isPeriodFilterOpen)}
      className='dashboardFilter__time'
    >
      <div className='dashboardFilter__time--title'>
        <span>{t('SelectPeriod')}:</span>
        <span>
          {
            timeFilterOptionsArr.find((item) => item.OptionValue === timeFilter)
              ?.OptionName
          }
        </span>
      </div>

      <AnimatePresence>
        {isPeriodFilterOpen && (
          <motion.ul
            className='dashboardFilter__time--filters'
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            {timeFilterOptionsArr.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    if (dashboardPeriodFilterHandler) {
                      dashboardPeriodFilterHandler(item.OptionValue);
                    }
                    setIsPeriodFilterOpen(false);
                  }}
                >
                  {item.OptionName}
                </li>
              );
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DashboardFilterTime;
